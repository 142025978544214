<template>
  <div>
    <div class="fx_big">
      <!-- 累计收益 -->
      <div class="fx_top">
        <div class="shouyi">
          <div>本月新增(人)</div>
          <div>{{ headerVal.month_add }}</div>
        </div>
        <div class="shouyi">
          <div>累计下级用户(人)</div>
          <div>{{ headerVal.add }}</div>
        </div>
        <div class="shouyi">
          <div>本月收益(元)</div>
          <div>{{ headerVal.month_profit }}</div>
        </div>

        <div class="shouyi">
          <div>累计收益(元)</div>
          <div>{{ headerVal.profit }}</div>
        </div>
      </div>
      <!-- 分销明细 -->
      <div class="mingxi_tabs">
        <div class="ming_left">分销明细</div>
        <!-- <div class="ming_right">
          <div class="dateTime">时间：</div>
          <el-date-picker
            v-model="value1"
            type="daterange"
            range-separator="~"
            start-placeholder="起始时间"
            end-placeholder="结束时间"
            size="small"
            prefix-icon="none"
            @change="chooseDate"
            value-format="yyyy-MM-dd"
          ></el-date-picker>
          <div class="serach">搜索</div>
        </div> -->
      </div>
      <!-- 分销表格 -->
      <el-table
        :data="tableData"
        style="width: 100%"
        :header-cell-style="headerStyle"
      >
        <!-- 下级用户 -->
        <el-table-column prop="class" label="下级用户">
          <template slot-scope="scope">
            <div class="fx_box">
              <img class="fx_img" :src="scope.row.face" alt />
              <div>{{ scope.row.username }}</div>
            </div>
          </template>
        </el-table-column>
        <!-- 下单量（单） -->
        <!-- <el-table-column
          prop="dan"
          label="下单量（单）"
          align="center"
        ></el-table-column> -->
        <!-- 购买金额（元） -->
        <el-table-column
          prop="paynum"
          label="购买金额（元）"
          align="center"
        ></el-table-column>
        <!-- 获取金额（元） -->
        <el-table-column
          prop="realnum"
          label="获取金额（元）"
          align="center"
        ></el-table-column>
        <!-- 最近一次下单时间 -->
        <el-table-column
          prop="addtime"
          label="最近一次下单时间"
          align="center"
        ></el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="page" v-if="tableData.length > 0">
        <el-pagination
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-size="size"
          layout="total, prev, pager, next, jumper"
          :total="tableData.length"
        ></el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import { pushBalance } from "../../../assets/js/personal";
export default {
  data() {
    return {
      URL: "http://v2.hnzye.cn/",
      headerVal: [],
      value1: "",
      currentPage: 1,
      size: 10,
      total: 100,
      tableData: [],
    };
  },
  mounted() {
    this.getBlance();
  },
  methods: {
    // 获取分销列表
    getBlance() {
      pushBalance({
        user_id: JSON.parse(window.localStorage.getItem("userInfo"))
          ? JSON.parse(window.localStorage.getItem("userInfo")).id
          : "",
        modal: 2,
        offset: this.currentPage,
        limit: this.size,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.headerVal = res.data.header;
          this.tableData = res.data.list;
        }
      });
    },
    chooseDate(val) {
      // console.log(val);
    },
    handleSizeChange(val) {
      // console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      // console.log(`当前页: ${val}`);
      this.currentPage = val;
      this.getBlance();
    },
    headerStyle() {
      return {
        background: "#ECECEC",
        color: "#333",
        padding: "12px 0",
        boxSizing: "border-box",
        textAlign: "center",
      };
    },
  },
};
</script>
<style lang="less" scoped>
/deep/ .el-date-editor--daterange.el-input,
.el-date-editor--daterange.el-input__inner,
.el-date-editor--timerange.el-input,
.el-date-editor--timerange.el-input__inner {
  width: 230px;
}
.fx_big {
  padding: 25px;
  box-sizing: border-box;
}
.fx_top {
  // width: 930px;
  height: 155px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 16px 0px rgba(12, 106, 231, 0.15);
  margin-bottom: 30px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  .shouyi {
    width: 300px;
    font-size: 14px;
    color: #333;
    text-align: center;
    border-right: 1px solid #dddddd;
    div:nth-child(2) {
      font-size: 32px;
      margin-top: 20px;
    }
  }
  .shouyi:last-child {
    border: none;
  }
}
.mingxi_tabs {
  // width: 930px;
  border-bottom: 1px solid rgba(241, 241, 241, 1);
  color: #333;
  padding-bottom: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .ming_left {
    font-size: 16px;
  }
  .ming_right {
    display: flex;
    align-items: center;
    .dateTime {
      font-size: 14px;
      color: #333;
    }
    .serach {
      width: 60px;
      height: 30px;
      background: rgba(12, 106, 231, 1);
      border-radius: 4px;
      color: #fff;
      line-height: 30px;
      text-align: center;
      margin-left: 20px;
      cursor: pointer;
    }
  }
}
.fx_box {
  display: flex;
  align-items: center;
  font-size: 14px !important;
  font-weight: bold;
  color: #333;
  .fx_img {
    width: 57px;
    height: 57px;
    border-radius: 50%;
    margin: 3px 15px;
  }
}
.page {
  // width: 930px;
  margin: 49px auto;
  text-align: center;
}
</style>