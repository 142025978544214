<template>
  <div>
    <div class="jifen_top">
      <div class="jifen_box">
        <div>我的积分</div>
        <div>10</div>
      </div>
      <div class="jifen_box2">
        <div>签到领积分</div>
        <div class="qiandao">签到</div>
        <div class="qiandao_date">签到日历</div>
      </div>
    </div>
    <!-- 积分历史记录 -->
    <div class="mingxi_tabs">
      <div class="ming_left">积分历史记录</div>
      <div class="ming_right">此处最多保留1个月的记录</div>
    </div>
    <!-- 积分表格 -->
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      :header-cell-style="{background:'#ECECEC',color:'#333'}"
    >
      <el-table-column prop="date" label="操作时间" align="center"></el-table-column>
      <el-table-column prop="from" label="来源" align="center" width="500"></el-table-column>
      <el-table-column prop="jifen" label="积分" align="center"></el-table-column>
    </el-table>
    <!-- 分页 -->
    <div class="page" v-if="tableData.length > 0">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-size="size"
        layout="total, prev, pager, next, jumper"
        :total="tableData.length"
      ></el-pagination>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      currentPage: 1,
      size: 2,
      total: 100,
      tableData: [
        {
          date: "2019-10-20  10:25",
          from: "连续签到1天，获取积分",
          jifen: "+5",
        },
        {
          date: "2019-10-20  10:25",
          from: "连续签到1天，获取积分",
          jifen: "+5",
        },
        {
          date: "2019-10-20  10:25",
          from: "连续签到1天，获取积分",
          jifen: "+5",
        },
        {
          date: "2019-10-20  10:25",
          from: "连续签到1天，获取积分",
          jifen: "+5",
        },
        {
          date: "2019-10-20  10:25",
          from: "连续签到1天，获取积分",
          jifen: "+5",
        },
        {
          date: "2019-10-20  10:25",
          from: "连续签到1天，获取积分",
          jifen: "+5",
        },
      ],
      handleSizeChange(val) {
        // console.log(`每页 ${val} 条`);
      },
      handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
      },
    };
  },
};
</script>
<style lang="less" scoped>
.jifen_top {
  display: flex;
  justify-content: space-between;
  .jifen_box,
  .jifen_box2 {
    width: 455px;
    height: 188px;
    border-radius: 10px;
    background: url(../../../assets/img/personal/img66.png) no-repeat;
    background-size: 100%;
    margin: 40px 0;
    color: #fff;
    text-align: center;
    overflow: hidden;
    div:nth-child(1) {
      font-size: 18px;
      margin-top: 38px;
      margin-bottom: 10px;
    }
    div:nth-child(2) {
      font-size: 44px;
    }
  }
  .jifen_box2 {
    position: relative;
    background: url(../../../assets/img/personal/img45.png) no-repeat;
    background-size: 100%;
    .qiandao {
      width: 120px;
      height: 40px;
      border: 2px solid rgba(255, 255, 255, 1);
      border-radius: 20px;
      font-size: 16px !important;
      color: #fff;
      line-height: 40px;
      text-align: center;
      margin: 0 auto;
      margin-top: 25px;
      cursor: pointer;
    }
    .qiandao_date {
      font-size: 12px;
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
}
.mingxi_tabs {
  width: 930px;
  border-bottom: 1px solid rgba(241, 241, 241, 1);
  color: #333;
  padding-bottom: 10px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  .ming_left {
    font-size: 16px;
    color: #333;
  }
  .ming_right {
    font-size: 12px;
    color: #999;
  }
}
.page {
  width: 930px;
  margin: 49px auto;
  text-align: center;
}
</style>