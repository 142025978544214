<template>
  <div>
    <div class="tabs_box">
      <div
        class="order_tabs"
        :class="{ active: active == index }"
        v-for="(item, index) in tabsList"
        :key="index"
        @click="changeTabs(index)"
      >
        {{ item }}
        <div v-if="active == index" class="border"></div>
      </div>
    </div>
    <!-- 余额 -->
    <Yue v-if="active == 0"></Yue>
    <!-- 分销 -->
    <Fenxiao v-if="active == 1"></Fenxiao>
    <!-- 积分 -->
    <!-- <Jifen v-if="active == 2"></Jifen> -->
  </div>
</template>
<script>
import Yue from "@/components/personal/zichan/yue.vue";
import Fenxiao from "@/components/personal/zichan/fenxiao.vue";
import Jifen from "@/components/personal/zichan/jifen.vue";
export default {
  components: {
    Yue,
    Fenxiao,
    Jifen,
  },
  data() {
    return {
      active: 0,
      tabsList: ["余额", "分销"],
    };
  },
  methods: {
    changeTabs(i) {
      this.active = i;
    },
  },
};
</script>
<style lang="less" scoped>
	img{
	  width: 100%;
	  height: 100%;
	}
.el-table {
  color: #666;
}
.tabs_box {
  display: flex;
  font-size: 16px;
  color: #656565;
  border-bottom: 1px solid #dddddd;
  // width: 900px;
  .order_tabs {
    height: 64px;
    line-height: 60px;
    // margin-right: 60px;
    padding: 0 20px;
    box-sizing: border-box;
    cursor: pointer;
    .border {
      // margin-top: 10px;
      border-bottom: 4px solid rgba(12, 106, 231, 1);
    }
  }
}
</style>